<template>
  <div>
    <el-dialog :title="row.title" :visible.sync="dialogVisible" width="600px" center :close-on-click-modal="false">
      <el-form ref="queryForm" label-position="right" label-width="150px" :model="queryForm" :rules="rules">
        <el-form-item label="BU" prop="bu">
          <Select
            v-model="queryForm.bu"
            api-key="buList"
            clearable
            :multiple="row.page==='auto'"
            :disabled="row.page==='resp'||row.type==='edit'"
          />
        </el-form-item>
        <el-form-item v-if="row.page==='resp'" label="Style" prop="style">
          <Select
            v-model="queryForm.style"
            api-key="styleList"
            clearable
            :disabled="row.type==='edit'"
            :configuration="optionsConfig"
            :find-length="findLength"
            @change="handleStyle"
          />
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <Select
            v-model="queryForm.platform"
            api-key="platformList"
            clearable
            :disabled="row.type==='edit'"
            @responseData="val=>platformList=val"
            @change="queryForm.site = ''"
          />
        </el-form-item>
        <el-form-item label="站点" prop="site">
          <Select
            v-model="queryForm.site"
            :params="{ sellPlatformId: queryForm.platform }"
            ganged
            clearable
            :ganged-prompt="$t('page.selectPlaceholder') + ' 平台'"
            api-key="siteList"
            :disabled="row.type==='edit'"
            @responseData="val=>siteList=val"
          />
        </el-form-item>
        <div v-for="(item,index) in queryForm.detail" :key="index">
          <el-form-item v-if="showItem(row,item)" :label="item.label">
            <el-select
              v-model="item['usernameList']"
              clearable
              :placeholder="$t('page.selectPlaceholder')"
              filterable
              multiple
            >
              <el-option
                v-for="i in userList"
                :key="i.employeeId"
                :label="i.username"
                :value="i.username"
                :disabled="item.role!==4&&i.username==='Style运营负责人'"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input', false)">取消</el-button>
        <el-button type="primary" :loading="checkLoading" @click="submit">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { getBuByStyle, insertPrincipal, updateOperationPrincipal, updatePlanPrincipal, insertSynPrincipal, updateOperator, updatePlan } from '@/api/configuration'
import Select from '@/components/Selection'
import { deepClone } from '@/utils'
import { userList } from '@/api/listSelection'
import { omit } from 'lodash'

export default {
  components: { Select },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    findLength: {
      type: Number,
      default: 100
    }

  },
  data() {
    return {
      userList: [],
      insertPrincipal,
      updateOperationPrincipal,
      updatePlanPrincipal,
      insertSynPrincipal,
      updateOperator,
      updatePlan,
      url: '',
      checkLoading: false,
      queryForm: {
        bu: this.isAuto ? [] : '', style: '', platform: '', site: '',
        detail: [
          { role: 1, label: 'Style运营负责人', username: '', flag: 'operate' },
          { role: 2, label: 'BU或市场负责人', flag: 'operate' },
          { role: 3, label: 'BG运营负责人', flag: 'operate' },
          { role: 4, label: 'Style计划负责人', flag: 'plan' },
          { role: 5, label: 'BU计划负责人', flag: 'plan' },
          { role: 6, label: 'BG计划负责人', flag: 'plan' },
          { role: 7, label: 'PJM', flag: 'plan' }
        ]
      },
      platformList: [],
      siteList: [],
      rules: {
        bu: [{ required: true, message: '必填', trigger: 'change' }],
        style: [{ required: true, message: '必填', trigger: 'change' }],
        platform: [{ required: true, message: '必填', trigger: 'change' }],
        site: [{ required: true, message: '必填', trigger: 'change' }]
      },
      optionsConfig: { key: 'id', label: 'styleName', value: 'styleName' }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    handleApi() {
      const { flag, type, page } = this.row
      let api
      if (type === 'add') {
        api = page === 'resp' ? insertPrincipal : insertSynPrincipal
      }
      if (flag === 'operate') {
        api = page === 'resp' ? updateOperationPrincipal : updateOperator
      }
      if (flag === 'plan') {
        api = page === 'resp' ? updatePlanPrincipal : updatePlan
      }
      return api
    },
    submitParams() {
      const { platform, site, detail, bu } = this.queryForm
      let arr = deepClone(detail)
      arr = arr.map(item => { return { role: item.role, usernameList: item.usernameList || [] } })
      const platformName = this.platformList.find(item => item.id === platform)?.platformName
      const siteId = this.siteList.find(item => item.siteCode === site)?.id
      return Object.assign({}, this.isAuto ? omit(this.queryForm, ['style']) : this.queryForm, {
        platformId: platform,
        platform: platformName,
        siteId, detail: arr, bu: (bu instanceof Array) ? bu.join(',') : bu
      })
    },

    isAuto() {
      return this.row.page === 'auto'
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        Object.assign(this.queryForm, this.$options.data.call(this).queryForm, { bu: this.isAuto ? [] : '' })
      } else {
        if (this.row.type === 'edit') {
          // 以queryForm.detail为准找回显值
          this.queryForm.detail.map(item => {
            this.row.detail.map(e => {
              if (e.role === item.role) {
                this.$set(item, 'usernameList', e.usernameList[0] ? e.usernameList : [])
              }
            })
          })
          const { style, platformId, bu, site } = this.row
          this.queryForm = Object.assign({}, this.queryForm, { style, platform: platformId, bu, site })
        }
        if (this.row.type === 'add') {
          Object.assign(this.queryForm, this.$options.data.call(this).queryForm)
        }
        this.handleUserList()
      }
      this.$refs.queryForm && this.$refs.queryForm.clearValidate()
    }
  },
  mounted() {

  },
  methods: {
    async handleUserList() {
      const { datas } = await userList()
      this.isAuto && datas.unshift({ username: 'Style运营负责人', employeeId: 'Style运营负责人' })
      this.userList = datas
    },
    showItem(row, item) {
      if (row.type === 'edit') {
        return !this.isAuto ? row.flag === item.flag : (row.flag === item.flag && item.role !== 1)
      } else {
        return !this.isAuto ? true : item.role !== 1
      }
    },
    async handleStyle(style) {
      const { datas: { buName }} = await getBuByStyle({ style })
      this.queryForm.bu = buName
    },
    submit() {
      this.$refs.queryForm.validate(async(valid) => {
        if (valid) {
          try {
            this.checkLoading = true
            const { code } = await this.handleApi(this.submitParams)
            if (code === 0) {
              this.$notify({
                title: '操作成功',
                message: '操作成功',
                type: 'success'
              })
              this.$emit('input', false)
              this.$emit('refresh')
            }
          } finally {
            this.checkLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input .el-input__inner {
  width: 100% !important;
}
</style>
