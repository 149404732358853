<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="500px"
    >
      <el-form ref="form" v-model="form">
        <el-form-item label="文件上传：">
          <ImportFile
            ref="upload"
            import-name="选择附件"
            :limit="1"
            :show-file-list="true"
            @getFile="({file:val})=>file=val"
            @removeFile="removeFile"
          />
        </el-form-item>
        <el-button type="text" @click="handleDownload">导入模板下载</el-button>
      </el-form>
      <span slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" @click="handleUpdata">导入预览</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="uploadDetailVisible" title="详情预览" width="1000px">
      <vxe-table
        ref="uploadTableDateRef"
        max-height="500px"
        align="center"
        :data="detailData"
        :cell-style="cellStyle"
        :row-config="{ height: 80 }"
        :checkbox-config="{checkMethod:tableCheckboxConfig}"
        @checkbox-all="({records})=>{dialogselectlist=records}"
        @checkbox-change="({records})=>{dialogselectlist=records}"
      >
        <vxe-table-column type="checkbox" />
        <vxe-table-column type="seq" width="60" title="序号" />
        <vxe-table-column
          field="errorMsg"
          title="错误信息"
          min-width="140"
        >
          <template #default="{row}">
            {{ row.errorMsg }}
          </template>
        </vxe-table-column>
        <template v-for="(col, index) in columns">
          <!-- 操作列/自定义列 -->
          <vxe-table-column
            :key="index"
            :field="col.prop"
            :title="col.label"
            :width="col.width"
            align="center"
          />
        </template>
      </vxe-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="uploadDetailVisible=false">取消</el-button>
        <el-button
          type="primary"
          :loading="importSubmitLoading"
          :disabled="!dialogselectlist.length"
          @click="submitDetailDatas"
        >
          提交
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'
import { detailsColumns } from '@/constant/tablecolumns'
import { handleDownload } from '@/utils/index'
export default {
  components: { ImportFile },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: undefined
    },
    submitApi: {
      type: Function,
      default: () => {}
    },
    viewApi: {
      type: Function,
      default: () => {}
    },
    contractType: {
      type: Number,
      default: undefined
    },
    templateDownloadApi: {
      type: Function,
      default: () => {}
    },
    fileName: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      detailsColumns,
      form: { },
      file: '',
      fileList: [],
      detailData: [],
      importSubmitLoading: false,
      dialogselectlist: [],
      uploadDetailVisible: false,
      differentErrorTip: '当前BU、平台、站点已有数据存在，确认导入将覆盖负责人配置数据;'
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    }
  },
  methods: {
    cellStyle({ row }) {
      const { errorMsg = '' } = row
      const tip = this.differentErrorTip.split(';')[0]
      if (errorMsg && (errorMsg === this.differentErrorTip || errorMsg === tip)) {
        return {
          color: 'orange'
        }
      }
      if (errorMsg) {
        return {
          color: 'red'
        }
      }
    },
    tableCheckboxConfig({ row }) {
      if (row.errorMsg) {
        const tip = this.differentErrorTip.split(';')[0]
        if (row.errorMsg === this.differentErrorTip || row.errorMsg === tip) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    async handleDownload() {
      const res = await this.templateDownloadApi()
      handleDownload(res, this.fileName + '.xlsx')
    },
    async submitDetailDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await this.submitApi(this.dialogselectlist)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.uploadDetailVisible = false
          this.$parent.handleQuery()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    async handleUpdata() {
      if (!this.file) {
        this.$message.error('请上传文件!')
        return
      } else {
        const formData = new FormData()
        formData.append('file', this.file)
        try {
          const { code, datas } = await this.viewApi(formData)
          if (code === 0) {
            this.detailData = datas
            this.$emit('input', false)
            this.uploadDetailVisible = true
          }
        } finally {
          this.$refs.upload.$refs.uploadForm.clearFiles()
          this.file = ''
        }
      }
    },
    removeFile(val) {
      this.file = val
    }
  }
}
</script>

<style>

</style>
