<template>
  <div>
    <el-dialog
      :title="row.title"
      :visible.sync="dialogVisible"
      width="600px"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="queryForm" label-position="right" label-width="150px" :model="queryForm" :rules="rules">
        <el-form-item label="请选择角色" prop="role">
          <Select
            v-model="queryForm.role"
            :select-options="roleList"
            :configuration="optionsRoleConfig"
            clearable
            @change="handlePrincipalList"
          />
        </el-form-item>
        <el-form-item label="请选择负责人" prop="oldUsername">
          <el-select v-model="queryForm.oldUsername" clearable :placeholder="$t('page.selectPlaceholder')" filterable>
            <el-option
              v-for="item in principalList"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="替换为" prop="newUsername">
          <el-select v-model="queryForm.newUsername" clearable :placeholder="$t('page.selectPlaceholder')" filterable>
            <el-option
              v-for="item in userList"
              :key="item.username"
              :label="item.username"
              :value="item.username"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input', false)">取消</el-button>
        <el-button type="primary" :loading="checkLoading" @click="submit">保存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { updatePrincipalBatch } from '@/api/configuration'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { Mixin } from '@/mixin/mixin.js'
import { userList } from '@/api/listSelection'

import Select from '@/components/Selection'

export default {

  components: { Select },
  mixins: [commodityInfoDict, Mixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    findLength: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      userList: [],
      optionsStyleConfig: { key: 'id', label: 'styleName', value: 'styleName' },
      optionsRoleConfig: { key: 'val', label: 'label', value: 'val' },
      optionsConfig: { key: 'username', label: 'username', value: 'username' },
      checkLoading: false,
      principalList: [],
      queryForm: { oldUsername: '', newUsername: '', role: '' },
      rules: {
        role: [{ required: true, message: '必填', trigger: 'change' }],
        newUsername: [{ required: true, message: '必填', trigger: 'change' }],
        oldUsername: [{ required: true, message: '必填', trigger: 'change' }],
        bu: [{ required: true, message: '必填', trigger: 'change' }],
        style: [{ required: true, message: '必填', trigger: 'change' }],
        platform: [{ required: true, message: '必填', trigger: 'change' }],
        site: [{ required: true, message: '必填', trigger: 'change' }]
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    submitParams() {
      const checkList = []
      this.row.multipleSelection.map(
        item => {
          const { platform, platformId, bu, site, siteId, style } = item
          checkList.push({ platform, platformId, bu, site, siteId, style })
        }
      )
      return Object.assign({}, this.queryForm, { checkList })
    },
    roleList() {
      return this._getAllCommodityDict('PRINCIPAL_CONFIG_TYPE').filter(item => item.val + '' !== '8').reverse()
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        Object.assign(this.queryForm, this.$options.data.call(this).queryForm)
        this.principalList = []
      }
      this.$refs.queryForm && this.$refs.queryForm.clearValidate()
    }
  },
  async mounted() {
    const { datas: data } = await userList()
    this.userList = data
  },
  methods: {
    changePlatform() {
      this.queryForm.site = ''
    },
    findSameItem(acc, curArray) {
      // curArray的每一项都在acc里
      return curArray && curArray.length && curArray.every(e => {
        return acc.includes(e)
      })
    },
    handlePrincipalList(val) {
      this.queryForm.oldUsername = ''
      const { role } = this.queryForm
      this.$nextTick(() => {
        this.principalList = this.row.multipleSelection.reduce((acc, cur) => {
          !this.findSameItem(acc, cur[role] && cur[role].split(',')) ? cur[role] && cur[role].split(',').map(e => !acc.includes(e) ? acc.push(e) : '') : acc
          return acc
        }, []).map(item => { return { username: item } })
      })
    },
    submit() {
      this.$refs.queryForm.validate(async(valid) => {
        if (valid) {
          try {
            this.checkLoading = true
            const { code, msg } = await updatePrincipalBatch(this.submitParams)
            if (code === 0) {
              this.$notify({
                title: msg,
                message: msg,
                type: 'success'
              })
              this.$emit('input', false)
              this.$emit('refresh')
            }
          } finally {
            this.checkLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-input  .el-input__inner {
    width: 100%!important;
}
</style>
